<template>
  <div class="row justify-content-center">
    <div class="col-7 search-container ">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>

    <div
      class="col-8 title-center"
      style="text-align: center;margin-top: 80px;"
    >
      <div class="info">
        <p>如有问题，请电话或邮箱联系我们的工作人员。</p>
        <p>电话：021-80370048 邮箱：yewu@insobo.com</p>
      </div>
    </div>
  </div>
</template>

<script>
import dialog from "../dialog/dialog";

export default {
  name: "",
  data() {
    return {};
  },

  mounted() {},
  components: {
    vdialog: dialog,
  },
  methods: {},

  computed: {},
  created() {},
};
</script>

<style lang="less" scoped>
.info p {
  height: 20px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #999999;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
  font-weight: 400;
}
p {
  margin-bottom: 0 !important;
}

.col-8 {
  padding-right: 0;
}
.title-center {
  margin-top: 10px;
  text-align: center;
}
// .search-container input {
//   height: 98%;
// }
// .search-container {
//   min-width: 480px;
//   height: 52px;
//   border: 2px solid #3a85fd;
//   background-color: #fff;
//   border-radius: 36px;
// }

@media (max-width: 460px) {
  .search-container {
    min-width: 90%;
  }
}
</style>
